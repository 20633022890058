var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.to
    ? _c(
        "router-link",
        {
          staticClass: "govuk-button",
          class: _vm.computedClasses,
          attrs: { to: _vm.to, disabled: _vm.disabled }
        },
        [_vm._t("default")],
        2
      )
    : _vm.href
    ? _c(
        "a",
        {
          staticClass: "govuk-button",
          class: _vm.computedClasses,
          attrs: { href: _vm.href, disabled: _vm.disabled }
        },
        [_vm._t("default")],
        2
      )
    : _c(
        "button",
        {
          staticClass: "govuk-button",
          class: _vm.computedClasses,
          attrs: { type: _vm.type, disabled: _vm.disabled },
          on: { click: _vm.onClick }
        },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }